* {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1.4rem;
  font-style: normal;
  color: var(--agj-color-gray-darkest);
  -webkit-tap-highlight-color: transparent;

  &::selection {
    color: white;
    background: var(--agj-color-primary);
  }
}

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  height: 100dvh;
}
