:root {
  --agj-color-link: #506aff;

  --agj-color-primary-darkest: #450088;
  --agj-color-primary-dark: #6500c7;
  --agj-color-primary: #8200ff;
  --agj-color-primary-light: #ac56ff;
  --agj-color-primary-lightest: #ca93ff;
  --agj-color-primary-lighter: #efdeff;
  --agj-color-primary-bg: #f9f1ff;

  --agj-color-gray-darkest: #222222;
  --agj-color-gray-dark: #333333;
  --agj-color-gray: #5c5c5c;
  --agj-color-gray-light: #888888;
  --agj-color-gray-lighter: #d1d1d1;
  --agj-color-gray-lightest: #e4e4e4;
  --agj-color-gray-bg: #f6f6f6;

  --agj-color-warn-dark: #d69e2e;
  --agj-color-warn: #f6e05e;
  --agj-color-warn-light: #fefcbf;

  --agj-color-danger-dark: #c53030;
  --agj-color-danger: #ff6363;
  --agj-color-danger-light: #fed7d7;

  --agj-color-info-dark: #3182ce;
  --agj-color-info: #63b3ed;
  --agj-color-info-light: #bee3f8;

  --agj-color-success-dark: #2f855a;
  --agj-color-success: #68d391;
  --agj-color-success-light: #c6f6d5;
}
