@font-face {font-family: "agj-icon";
  src: url('agj-icon.eot?t=1721573304146'); /* IE9*/
  src: url('agj-icon.eot?t=1721573304146#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("agj-icon.woff2?t=1721573304146") format("woff2"),
  url("agj-icon.woff?t=1721573304146") format("woff"),
  url('agj-icon.ttf?t=1721573304146') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('agj-icon.svg?t=1721573304146#agj-icon') format('svg'); /* iOS 4.1- */
}

[class^="agj-icon-"], [class*=" agj-icon-"] {
  font-family: 'agj-icon' !important;font-size: 16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.agj-icon-arrow-down-thin:before { content: "\ea01"; }
.agj-icon-arrow-down:before { content: "\ea02"; }
.agj-icon-arrow-left:before { content: "\ea03"; }
.agj-icon-arrow-long-left:before { content: "\ea04"; }
.agj-icon-arrow-right:before { content: "\ea05"; }
.agj-icon-arrow-up:before { content: "\ea06"; }
.agj-icon-baby:before { content: "\ea07"; }
.agj-icon-battery:before { content: "\ea08"; }
.agj-icon-calendar:before { content: "\ea09"; }
.agj-icon-camera:before { content: "\ea0a"; }
.agj-icon-car:before { content: "\ea0b"; }
.agj-icon-check-thin:before { content: "\ea0c"; }
.agj-icon-check:before { content: "\ea0d"; }
.agj-icon-circle-check:before { content: "\ea0e"; }
.agj-icon-circle-facebook:before { content: "\ea0f"; }
.agj-icon-circle-instagram:before { content: "\ea10"; }
.agj-icon-circle-linkedin:before { content: "\ea11"; }
.agj-icon-circle-pinterest:before { content: "\ea12"; }
.agj-icon-circle-twitter:before { content: "\ea13"; }
.agj-icon-circle-whatsapp:before { content: "\ea14"; }
.agj-icon-circle-youtube:before { content: "\ea15"; }
.agj-icon-copy:before { content: "\ea16"; }
.agj-icon-cursor-click:before { content: "\ea17"; }
.agj-icon-edit-rect:before { content: "\ea18"; }
.agj-icon-exit:before { content: "\ea19"; }
.agj-icon-external-link:before { content: "\ea1a"; }
.agj-icon-heart-outline:before { content: "\ea1b"; }
.agj-icon-heart:before { content: "\ea1c"; }
.agj-icon-house:before { content: "\ea1d"; }
.agj-icon-instagram:before { content: "\ea1e"; }
.agj-icon-location:before { content: "\ea1f"; }
.agj-icon-pcd:before { content: "\ea20"; }
.agj-icon-pen:before { content: "\ea21"; }
.agj-icon-phone:before { content: "\ea22"; }
.agj-icon-plus:before { content: "\ea23"; }
.agj-icon-reply:before { content: "\ea24"; }
.agj-icon-schedule:before { content: "\ea25"; }
.agj-icon-search:before { content: "\ea26"; }
.agj-icon-send:before { content: "\ea27"; }
.agj-icon-share:before { content: "\ea28"; }
.agj-icon-shopping-bag:before { content: "\ea29"; }
.agj-icon-snow:before { content: "\ea2a"; }
.agj-icon-sort:before { content: "\ea2b"; }
.agj-icon-star-half:before { content: "\ea2c"; }
.agj-icon-star-outline:before { content: "\ea2d"; }
.agj-icon-star:before { content: "\ea2e"; }
.agj-icon-store:before { content: "\ea2f"; }
.agj-icon-thumbs-down:before { content: "\ea30"; }
.agj-icon-thumbs-up:before { content: "\ea31"; }
.agj-icon-trash:before { content: "\ea32"; }
.agj-icon-user:before { content: "\ea33"; }
.agj-icon-users:before { content: "\ea34"; }
.agj-icon-wifi:before { content: "\ea35"; }
.agj-icon-x:before { content: "\ea36"; }

$agj-icon-arrow-down-thin: "\ea01";
$agj-icon-arrow-down: "\ea02";
$agj-icon-arrow-left: "\ea03";
$agj-icon-arrow-long-left: "\ea04";
$agj-icon-arrow-right: "\ea05";
$agj-icon-arrow-up: "\ea06";
$agj-icon-baby: "\ea07";
$agj-icon-battery: "\ea08";
$agj-icon-calendar: "\ea09";
$agj-icon-camera: "\ea0a";
$agj-icon-car: "\ea0b";
$agj-icon-check-thin: "\ea0c";
$agj-icon-check: "\ea0d";
$agj-icon-circle-check: "\ea0e";
$agj-icon-circle-facebook: "\ea0f";
$agj-icon-circle-instagram: "\ea10";
$agj-icon-circle-linkedin: "\ea11";
$agj-icon-circle-pinterest: "\ea12";
$agj-icon-circle-twitter: "\ea13";
$agj-icon-circle-whatsapp: "\ea14";
$agj-icon-circle-youtube: "\ea15";
$agj-icon-copy: "\ea16";
$agj-icon-cursor-click: "\ea17";
$agj-icon-edit-rect: "\ea18";
$agj-icon-exit: "\ea19";
$agj-icon-external-link: "\ea1a";
$agj-icon-heart-outline: "\ea1b";
$agj-icon-heart: "\ea1c";
$agj-icon-house: "\ea1d";
$agj-icon-instagram: "\ea1e";
$agj-icon-location: "\ea1f";
$agj-icon-pcd: "\ea20";
$agj-icon-pen: "\ea21";
$agj-icon-phone: "\ea22";
$agj-icon-plus: "\ea23";
$agj-icon-reply: "\ea24";
$agj-icon-schedule: "\ea25";
$agj-icon-search: "\ea26";
$agj-icon-send: "\ea27";
$agj-icon-share: "\ea28";
$agj-icon-shopping-bag: "\ea29";
$agj-icon-snow: "\ea2a";
$agj-icon-sort: "\ea2b";
$agj-icon-star-half: "\ea2c";
$agj-icon-star-outline: "\ea2d";
$agj-icon-star: "\ea2e";
$agj-icon-store: "\ea2f";
$agj-icon-thumbs-down: "\ea30";
$agj-icon-thumbs-up: "\ea31";
$agj-icon-trash: "\ea32";
$agj-icon-user: "\ea33";
$agj-icon-users: "\ea34";
$agj-icon-wifi: "\ea35";
$agj-icon-x: "\ea36";

